@import '~normalize.css';
@import './abstracts/_variables';
@import './abstracts/_fonts';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;

	&::before,
	&::after {
		box-sizing: inherit;
	}
}

html {
	scroll-behavior: smooth;

	font-size: 1.1111vw;

	background: var(--color-main);
	color: var(--color-white);

	@media (min-width: 992px) {
		&.scaled-down {
			.h1 {
				font-size: 2.5rem;
				strong {
					font-size: 3rem;
				}
			}
			.h2 {
				font-size: 2rem;
				strong {
					font-size: 2.5rem;
				}
			}
		}
	}

	@media (min-width: 1900px) {
		font-size: 1vw;
	}

	@media (min-width: 2000px) {
		font-size: 0.9vw;
	}
	@media (min-width: 3000px) {
		font-size: 0.8vw;
	}

	@media (max-width: 991.98px) {
		font-size: 3vw;
	}
	@media (orientation: landscape) and (max-width: 991.98px) {
		font-size: 1.8vw;
	}
	@media (orientation: landscape) and (max-width: 699.98px) {
		font-size: 2.2vw;
	}
	@media (max-width: 575.98px) {
		// font-size: 4.26665vw;
		font-size: 4.05vw;
	}
}

body {
	font-size: 1rem;
	font-family: var(--font-main);
	font-size: inherit;
	line-height: 135%;

	display: flex;
	flex-direction: column;
	-webkit-tap-highlight-color: transparent;

	background: var(--color-main);
	color: var(--color-white);

	@media (max-width: 991.98px) {
		font-size: 0.75rem;
	}

	&.lock {
		overflow: hidden;
	}
}

input,
textarea {
	&:not([type='checkbox']) {
		outline: none;
		appearance: none;

		background-image: none;
		background-color: transparent;
		box-shadow: none;
	}

	&::placeholder {
		transition: 0.3s;
	}
	&:focus {
		&::placeholder {
			opacity: 0;
		}
	}
}

button {
	background-color: transparent;
	border-width: 0;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
	padding: 0;
	cursor: pointer;
	transition: 0.3s;
	outline: none;
	appearance: none;
	text-decoration: none;
}

b {
	font-weight: bold;
}

a {
	text-decoration-line: none;
	color: inherit;
	transition: 0.3s;

	&:hover {
		color: var(--color-major);
	}
}

select {
	appearance: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

html,
body,
#root {
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}

main {
	flex: 1 1 auto;
}

footer {
	flex-shrink: 0;
}

/* Global styles */

.iresponsive {
	position: relative;

	img,
	video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		z-index: 1;
	}

	iframe {
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	svg {
		width: 100%;
		height: 100%;
		transition: 0.3s;
		fill: inherit;
	}
}

.container {
	margin: 0 auto;
	max-width: var(--size-container);
	width: 100%;

	padding: 0 1.25rem;

	@media (max-width: 991.98px) {
		padding: 0 0.875rem;
		max-width: 30rem;
	}

	@media (orientation: landscape) and (max-width: 991.98px) {
		max-width: 40rem;
	}
	@media (orientation: landscape) and (max-width: 699.98px) {
		max-width: 38rem;
	}

	@media (max-width: 991.98px) {
	}
}

.h1,
.h2,
.h3,
.h4,
.h5 {
	margin: 0;
}

h1,
.h1 {
	// font-size: 3rem;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 250;
	line-height: normal;
	text-transform: uppercase;

	@media (max-width: 991.98px) {
		font-size: 1rem;
	}

	strong {
		// font-size: 4rem;
		font-size: 3.7rem;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;

		@media (max-width: 991.98px) {
			font-size: 2rem;
		}
	}
}

h2,
.h2 {
	// font-size: 2.5rem;
	font-size: 2.2rem;
	font-style: normal;
	font-weight: 250;
	line-height: normal;
	text-transform: uppercase;

	@media (max-width: 991.98px) {
		font-size: 1.5rem;
	}

	strong {
		// font-size: 3rem;
		font-size: 2.7rem;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;

		@media (max-width: 991.98px) {
			font-size: 2.25rem;
		}
	}
}
.title {
	// font-size: 2.5rem;
	font-size: 2.2rem;
	font-style: normal;
	font-weight: 250;
	line-height: normal;
	text-transform: uppercase;

	@media (max-width: 991.98px) {
		font-size: 1.5rem;
	}

	strong {
		// font-size: 3rem;
		font-size: 2.7rem;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;

		@media (max-width: 991.98px) {
			font-size: 2.25rem;
		}
	}
}

h3,
.h3 {
	font-size: 1.5rem;
	font-style: italic;
	font-weight: 250;
	line-height: normal;

	strong {
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: uppercase;
	}
}

.arrows {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.arrow {
	user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 3.1875rem;
	height: 3.1875rem;
	overflow: hidden;

	flex-shrink: 0;
	border-radius: 50%;

	background: transparent;

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: radial-gradient(
					43.14% 43.14% at 28.43% 58.82%,
					rgba(255, 209, 1, 0.2) 0%,
					rgba(255, 209, 1, 0) 100%
				)
				/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
			rgba(255, 255, 255, 0.1);
		transform: rotate(-180deg);

		transition: 0.3s;
	}

	transition: 0.3s;

	.icon {
		z-index: 2;
		position: relative;
		height: 1rem;
		width: 1rem;

		svg {
			fill: var(--color-major);
		}
	}

	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	&.swiper-button-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&.swiper-button-lock {
		opacity: 0;
		pointer-events: none;
	}
}

.Toastify__toast-theme--dark {
	font-family: var(--font-main) !important;
	background: var(--color-main) !important;
}

.ticker-view {
	padding-right: 0.1875rem;
}
