.footer {
	border-radius: 2.5rem 2.5rem 0rem 0rem;
	background: var(--color-light2);

	color: var(--color-main2);

	overflow: hidden;

	position: relative;

	padding: 2.625rem 3.125rem;

	@media (max-width: 991.98px) {
		padding: 2.625rem 0;
		padding-bottom: 0;
	}
}

.element {
	position: absolute;
	top: 0;
	right: 0;

	height: 100%;
	width: 20.125rem;

	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right;
	}

	@media (min-width: 992px) {
		bottom: 0;
	}

	@media (max-width: 991.98px) {
		width: 16.3125rem;
		height: 17.5rem;
	}
}

.wrapper {
	position: relative;
	z-index: 2;
}

.copyright {
	margin-top: 2rem;

	background: #151516;
	color: var(--color-white);

	padding: 0.875rem 0.9375rem;

	text-align: center;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;

	color: #f3f3f3;
}

.logo {
	display: block;

	width: 5.6875rem;
	height: 1.8375rem;
	flex-shrink: 0;

	@media (max-width: 991.98px) {
		width: 5.6875rem;
		height: 1.8375rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.list {
	display: flex;
	flex-direction: column;

	gap: 0.8125rem;

	font-size: 1rem;
	font-style: italic;
	font-weight: 250;
	line-height: normal;

	&.list__svg {
		> ul {
			gap: 0.625rem;
		}
	}

	> ul {
		display: flex;
		flex-direction: column;
		gap: 0.3125rem;

		> li {
			position: relative;
			svg {
				flex-shrink: 0;

				width: 1.5rem;
				height: 1.5rem;

				fill: var(--color-main2);

				transition: 0.3s;
			}

			a {
				cursor: pointer;

				display: flex;
				align-items: center;

				gap: 1rem;

				&:hover {
					svg {
						fill: var(--color-major);
					}
				}
			}

			> span {
				cursor: pointer;
				user-select: none;
				transition: 0.3s;

				display: flex;
				align-items: center;

				gap: 0.3125rem;

				&::before {
					content: '';

					flex-shrink: 0;

					width: 0.5rem;
					height: 0.5rem;

					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;

					transition: 0.3s;

					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='7' viewBox='0 0 6 7' fill='none'%3E%3Cpath d='M6 3.5L-3.26266e-07 6.9641L-2.34249e-08 0.0358982L6 3.5Z' fill='%231E1E1E'/%3E%3C/svg%3E");
				}

				&:hover {
					opacity: 0.6;
				}
			}

			&.open {
				> span {
					&::before {
						transform: rotate(90deg);
					}
				}
			}
			ul {
				font-size: 0.875rem;
				font-style: italic;
				font-weight: 250;
				line-height: normal;

				padding-left: 0.8125rem;
				margin-top: 0.3125rem;

				display: flex;
				flex-direction: column;
				gap: 0.25rem;

				@media (min-width: 992px) {
					z-index: 5;
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;

					background-color: var(--color-light1);
					@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
						backdrop-filter: blur(1.5625rem);
						transform: translate3d(0, 0, 0);
					}

					padding: 0.25rem 0.5rem;
				}
			}
		}
	}
}

.label {
	// font-size: 1.5rem;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}

.columns {
	display: flex;
	align-items: stretch;

	gap: 5.3125rem;

	@media (max-width: 991.98px) {
		flex-direction: column;
		gap: 1.25rem;

		padding: 0 0.0625rem;
	}
}

.column {
	display: flex;
	flex-direction: column;

	gap: 0.75rem;

	max-width: 13.5rem;

	@media (max-width: 991.98px) {
		max-width: 60%;
	}
}

.text {
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	// max-width: 9rem;
	max-width: 10rem;

	@media (max-width: 991.98px) {
		font-size: 0.875rem;
	}
}
