.section {
	margin-top: -20.1875rem;

	overflow: hidden;

	@media (max-width: 991.98px) {
		margin-top: -12.1875rem;
	}
}

.head {
	margin-bottom: 2.375rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	gap: 1rem 4.8125rem;

	@media (max-width: 991.98px) {
		flex-direction: column;
		align-items: stretch;
		gap: 1.875rem;
		margin-bottom: 1.875rem;
	}
}

.progressbar {
	flex: 1;
	position: relative;

	:global(.swiper-pagination-progressbar) {
		background: rgba(255, 255, 255, 0.1);
		height: 0.0625rem !important;
	}
	:global(.swiper-pagination-progressbar .swiper-pagination-progressbar-fill) {
		border-radius: 0.625rem;
		background: #d9d9d9;

		height: 0.1875rem !important;
		top: -0.0625rem;
	}

	@media (max-width: 991.98px) {
		width: 100%;
	}
}

.title {
	@media (max-width: 991.98px) {
		max-width: 11.875rem;
	}
}

.arrows {
	display: flex;
	align-items: center;

	gap: 0.5rem;
}

.arrow {
	cursor: pointer;
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-shrink: 0;
	width: 0.9375rem;
	height: 0.9375rem;

	svg {
		width: 100%;
		height: 100%;
		fill: var(--color-white);
	}
}

.slider {
	width: calc(100% + 3.125rem * 2);
	margin-left: -3.125rem !important;

	padding: 0 3.125rem !important;

	@media (max-width: 991.98px) {
		width: calc(100% + 0.875rem * 2);

		margin-left: -0.875rem !important;

		padding: 0 0.875rem !important;
	}
}

.item {
	position: relative;

	@media (min-width: 992px) {
		padding-bottom: 24.05%;

		background: #1a2022;

		border-radius: 1.25rem;
		overflow: hidden;

		&:hover {
			.item__image {
				&::before,
				&::after {
					opacity: 1;
					visibility: visible;
				}
			}
			.item__wrapper {
				opacity: 1;
				visibility: visible;

				transform: none;
			}
		}
	}

	@media (max-width: 991.98px) {
		height: auto !important;
		display: flex !important;
		flex-direction: column;
	}

	&__image {
		display: flex;
		@media (min-width: 992px) {
			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			pointer-events: none;
		}
		@media (max-width: 991.98px) {
			position: relative;
			height: 11.75rem;
			width: 100%;
			overflow: hidden;
			border-radius: 1.25rem;

			background: radial-gradient(4631.99% 85.45% at 100% 100%, #00a2eb 0%, rgba(0, 0, 0, 0) 100%),
				#1a2022;
		}

		&::before {
			transition: 0.4s;

			content: '';

			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			z-index: 2;

			@media (min-width: 992px) {
				background: radial-gradient(
						4993.28% 131.53% at 100% 0%,
						rgba(0, 162, 235, 0.52) 0%,
						rgba(0, 0, 0, 0) 100%
					),
					rgba(26, 32, 34, 0.52);
				backdrop-filter: blur(0.4375rem);
				transform: translate3d(0, 0, 0);

				opacity: 0;
				visibility: hidden;
				transform: translateZ(0);
			}

			@media (max-width: 991.98px) {
				border-radius: 1.25rem;
				border: 0.0625rem solid #9b9b9b;
			}
		}
		&::after {
			z-index: 3;

			transition: 0.4s;

			opacity: 0;
			visibility: hidden;

			content: '';

			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			border: 0.0625rem solid #9b9b9b;

			border-radius: 1.25rem;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			// display: none;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 0.6875rem;

		@media (max-width: 991.98px) {
			margin-top: 1.25rem;

			flex: 1;
		}

		@media (min-width: 992px) {
			transition: 0.4s;

			opacity: 0;
			visibility: hidden;

			transform: translateX(-30%);

			position: absolute;

			z-index: 4;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			padding: 3.125rem;
		}
	}

	&__title {
		font-size: 2.25rem;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;

		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

	&__text {
		text-align: justify;
		font-size: 1rem;
		font-style: normal;
		font-weight: 300;
		line-height: normal;

		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;

		max-width: 23.3125rem;

		@media (max-width: 991.98px) {
			font-size: 12px;
		}
	}

	&__button {
		margin-top: 0.3125rem;

		min-width: 14.5rem;

		@media (max-width: 991.98px) {
			width: 100%;
			margin-top: auto;
		}
	}
}
