.list {
  ul {
    display: flex;
    align-items: center;

    gap: 0.9375rem;

    @media (max-width: 991.98px) {
      justify-content: center;
    }

    li {
      a {
        width: 2.8125rem;
        height: 2.8125rem;
        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }

        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .subtext {
    margin-top: 1.375rem;

    font-size: 1.2rem;
    line-height: 135%;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: rgba(250, 250, 250, 0.9);
  }
}
