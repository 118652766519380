.section {
	border-radius: 2.5rem;
	background: var(--color-light2);
	color: var(--color-main);

	overflow: hidden;

	position: relative;

	padding-top: 6.75rem;
	padding-bottom: 4.875rem;

	@media (max-width: 991.98px) {
		padding: 1.875rem 0.0625rem;
		padding-bottom: 8.6875rem;
	}
}

.element {
	width: 14.6875rem;
	height: 20.125rem;

	position: absolute;

	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: bottom left;
	}

	@media (min-width: 992px) {
		top: 0;
		right: 0;
	}
	@media (max-width: 991.98px) {
		bottom: 0;
		right: 0;

		height: 16.0625rem;
		width: 16.125rem;
	}
}

.image {
	position: absolute;

	height: 100%;
	width: 100%;

	filter: blur(2.5rem);
  transform: translate3d(0, 0, 0);

	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		object-position: left;
	}

	@media (min-width: 992px) {
		top: 50%;

		transform: translateY(-50%);
		left: 0;
	}
	@media (max-width: 991.98px) {
		width: 23.4375rem;
		height: 32.0625rem;

		filter: blur(1.875rem);

		top: 0;
		right: 0;
	}
}

.wrapper {
	position: relative;
	z-index: 4;
}

.head {
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	@media (max-width: 991.98px) {
		flex-direction: column;
		gap: 0.625rem;
	}

	gap: 2rem;
}

.title {
	max-width: 17.8125rem;
	width: 100%;
}

.text {
	padding-top: 0.5625rem;

	max-width: 48.4375rem;
	width: 100%;

	margin-right: 7.0625rem;

	text-align: justify;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media (max-width: 991.98px) {
		padding: 0;
	}
}

.body {
	margin-top: 1.9375rem;

	display: flex;
	flex-direction: column;

	gap: 2.6875rem;

	@media (max-width: 991.98px) {
		gap: 1.5625rem;

		margin-top: 1.5625rem;
	}
}

.list {
	ul {
		display: flex;
		gap: 1rem;

		@media (max-width: 991.98px) {
			flex-direction: column;
		}
		li {
			flex: 1;
			display: flex;
			flex-direction: column;
			span {
				font-size: 1rem;
				font-weight: 300;
				line-height: normal;
				letter-spacing: 0.1rem;

				display: block;

				padding-bottom: 0.6875rem;

				position: relative;

				margin-bottom: 0.25rem;

				@media (max-width: 991.98px) {
					font-size: 0.75rem;
					padding-bottom: 0.5625rem;
					letter-spacing: 0.075rem;
					margin: 0;
				}

				&::before {
					position: absolute;
					content: '';

					bottom: 0;
					left: 0;
					width: 100%;

					height: 0.0625rem;
					background-color: var(--color-black);

					opacity: 0.3;
				}
			}

			strong {
				font-size: 3rem;
				font-style: italic;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;

				@media (max-width: 991.98px) {
					font-size: 2.5rem;
				}

				small {
					font-size: 1.5rem;
					font-style: italic;
					font-weight: 700;

					@media (max-width: 991.98px) {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}

.descr {
	max-width: 55.5rem;

	text-align: justify;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}

.foot {
	margin-top: 3.3125rem;

	display: flex;
	justify-content: space-between;

	gap: 2rem;

	@media (max-width: 991.98px) {
		flex-direction: column;

		gap: 1.5625rem;
	}

	&__title {
		max-width: 22.5rem;

		@media (max-width: 991.98px) {
			// max-width: 15rem;
			max-width: 17rem;
		}
	}

	@media (max-width: 991.98px) {
		margin-top: 1.5625rem;
	}
}

.form {
	margin-top: 0.25rem;

	max-width: 55.5rem;
	width: 100%;

	@media (max-width: 991.98px) {
		max-width: 100%;
		margin: 0;
	}
}
