.section {
	background: radial-gradient(
			92.56% 114.8% at 100% 0%,
			rgba(143, 3, 229, 0.31) 0%,
			rgba(143, 3, 229, 0) 100%
		),
		radial-gradient(
			86.84% 107.65% at 0% 100%,
			rgba(0, 162, 235, 0.28) 0%,
			rgba(0, 162, 235, 0) 100%
		);

	margin-top: -3.375rem;
	padding-top: 7.3125rem;
	padding-bottom: 25.25rem;

	@media (max-width: 991.98px) {
		padding-top: 5.375rem;
		padding-bottom: 15.8125rem;

		margin-top: -2.875rem;
	}
}

.items {
	margin-top: 2.375rem;

	display: flex;
	flex-wrap: wrap;

	gap: 1.4375rem 1rem;

	@media (max-width: 991.98px) {
		margin-top: 1.875rem;
		gap: 1rem;
	}

	.item {
		@media (min-width: 992px) {
			width: calc(100% / 2 - 1rem / 2);

			&:nth-child(1),
			&:nth-child(4) {
				width: calc(41.5% - 0.5rem);
			}
			&:nth-child(2),
			&:nth-child(3) {
				width: calc(58.5% - 0.5rem);
			}
		}
	}
}

.title {
	// max-width: 15rem;
	max-width: 40rem;
}

.item {
	display: flex;
	align-items: center;

	border-radius: 1.25rem;
	background: rgba(255, 255, 255, 0.1);

	padding: 2.1875rem 3rem 2.1875rem 1.9375rem;
	gap: 1.375rem;

	width: 100%;

	@media (max-width: 991.98px) {
		padding: 1.25rem 1.5625rem;

		gap: 1.25rem;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;

		flex-shrink: 0;

		width: 2.75rem;
		height: 2.75rem;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--color-white);
		}
	}

	&__wrapper {
		padding-left: 1.375rem;

		position: relative;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;

		@media (max-width: 991.98px) {
			padding-left: 1.25rem;
		}

		&::before {
			position: absolute;

			content: '';

			top: 50%;
			transform: translateY(-50%);

			left: 0;
			width: 0.0625rem;
			height: 3.25rem;
			background: rgba(255, 255, 255, 0.1);
		}

		strong {
			font-size: 1rem;
			font-weight: 600;
			line-height: normal;
			text-transform: uppercase;
		}

		span {
			text-align: justify;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}
}
