.modal {
	padding: 2.25rem 2.5rem;

	display: flex;
	align-items: stretch;
	justify-content: space-between;

	gap: 2rem;

	@media (max-width: 991.98px) {
		padding: 2.125rem 1rem;
	}

	position: relative;

	& > * {
		position: relative;
		z-index: 2;
	}

	.image {
		position: absolute;

		height: 100%;
		width: 73%;
		z-index: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: bottom left;
		}
		@media (min-width: 992px) {
			top: 0;
			left: 0;
		}
		@media (max-width: 991.98px) {
			transform: rotate(-156.728deg);
			filter: blur(1.375rem);
			transform: translate3d(0, 0, 0);
			bottom: 0;
			right: 0;
		}
	}

	.title {
		max-width: 27rem;
		width: 100%;

		font-size: 2.5rem;
		font-weight: 200;
		line-height: normal;
		text-transform: uppercase;

		strong {
			font-size: 3rem;
			font-weight: 800;
		}

		@media (max-width: 991.98px) {
			display: none;
		}
	}

	.form {
		max-width: 21.5rem;
		width: 100%;

		display: flex;
		flex-direction: column;

		@media (max-width: 991.98px) {
			max-width: 100%;
		}
	}

	.head {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.25rem;

		gap: 1.25rem;

		&__buttons {
			display: flex;
			align-items: center;
			gap: 1.25rem;
		}

		&__button {
			flex: 1;
			padding: 0.3125rem 1.25rem;
			height: 2.5rem;

			border-radius: 0.625rem;

			gap: 0.625rem;
			font-size: 0.625rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.0625rem;
			text-transform: uppercase;
		}

		&__text {
			text-align: center;
			font-size: 0.75rem;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.075rem;
			text-transform: uppercase;

			position: relative;

			display: flex;
			align-items: center;

			gap: 2.375rem;

			&::before {
				content: '';
				flex: 1;
				height: 0.0625rem;
				background: var(--color-white);
				box-shadow: 0rem 1.5rem 2.125rem 0rem rgba(150, 49, 212, 0.22);
			}
			&::after {
				content: '';
				flex: 1;
				height: 0.0625rem;
				background: var(--color-white);
				box-shadow: 0rem 1.5rem 2.125rem 0rem rgba(150, 49, 212, 0.22);
			}
		}
	}

	.label {
		text-align: center;
		align-self: center;

		font-size: 1.5rem;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
	}
}
