.form {
}

.content {
	display: flex;
	flex-direction: column;

	gap: 0.625rem;

	@media (max-width: 991.98px) {
		gap: 0.5rem;
	}
}

.fields {
	display: flex;
	flex-direction: column;

	gap: 0.625rem;
	.field {
		input {
			border-color: rgba($color: #fff, $alpha: 0.34);
			color: var(--color-white);

			&::placeholder {
				color: rgba($color: #fff, $alpha: 0.34);
			}
		}
	}
}

.controls {
	display: flex;
	flex-direction: column;

	gap: 0.625rem;

	@media (max-width: 991.98px) {
	}

	.checkbox {
		> span {
			filter: invert(1);
		}
	}
}
