@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-500.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-500'),
		url('../../assets/fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-500.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-300Italic.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-300Italic'),
		url('../../assets/fonts/MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-300Italic.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-300Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-500Italic.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-500Italic'),
		url('../../assets/fonts/MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-500Italic.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-500Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-900.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-900'),
		url('../../assets/fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-900.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-700.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-700'),
		url('../../assets/fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-700.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-700Italic.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-700Italic'),
		url('../../assets/fonts/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-700Italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-300.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-300'),
		url('../../assets/fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-300.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-100.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-100'),
		url('../../assets/fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-100.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-100.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-100Italic.eot');
	src: local('../../assets/fonts/MuseoSansCyrl-100Italic'),
		url('../../assets/fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-100Italic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: url('../../assets/fonts/MuseoSansCyrl-900Italic.eot');
	src: local('../../assets/fonts/Museo Sans Cyrl 900 Italic'),
		local('../../assets/fonts/MuseoSansCyrl-900Italic'),
		url('../../assets/fonts/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
		url('../../assets/fonts/MuseoSansCyrl-900Italic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-Medium.eot');
	src: local('Inter Medium'), local('Inter-Medium'),
		url('../../assets/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-Medium.woff2') format('woff2'),
		url('../../assets/fonts/Inter-Medium.woff') format('woff'),
		url('../../assets/fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-MediumItalic.eot');
	src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
		url('../../assets/fonts/Inter-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-MediumItalic.woff2') format('woff2'),
		url('../../assets/fonts/Inter-MediumItalic.woff') format('woff'),
		url('../../assets/fonts/Inter-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-Regular.eot');
	src: local('Inter Regular'), local('Inter-Regular'),
		url('../../assets/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
		url('../../assets/fonts/Inter-Regular.woff') format('woff'),
		url('../../assets/fonts/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-Italic.eot');
	src: local('Inter Italic'), local('Inter-Italic'),
		url('../../assets/fonts/Inter-Italic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-Italic.woff2') format('woff2'),
		url('../../assets/fonts/Inter-Italic.woff') format('woff'),
		url('../../assets/fonts/Inter-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-Bold.eot');
	src: local('Inter Bold'), local('Inter-Bold'),
		url('../../assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-Bold.woff2') format('woff2'),
		url('../../assets/fonts/Inter-Bold.woff') format('woff'),
		url('../../assets/fonts/Inter-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter-BoldItalic.eot');
	src: local('Inter Bold Italic'), local('Inter-BoldItalic'),
		url('../../assets/fonts/Inter-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Inter-BoldItalic.woff2') format('woff2'),
		url('../../assets/fonts/Inter-BoldItalic.woff') format('woff'),
		url('../../assets/fonts/Inter-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
