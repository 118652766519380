.project {
	display: flex;
	flex-direction: column;
	position: relative;

	& > * {
		position: relative;
		z-index: 2;
	}

	&__bg {
		position: absolute;
		pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 23.125rem;
		overflow: hidden;
		z-index: 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;

			opacity: 0.2;
			transform: translate3d(0, 0, 0);
			filter: blur(0.3125rem);
		}
	}

	.button {
		padding-left: 1.875rem;
		padding-right: 1.875rem;

		div {
			width: 0.9375rem;
			height: 0.9375rem;
		}

		@media (max-width: 991.98px) {
			padding: 0.5rem 1.875rem;
			height: 2.5rem;
			border-radius: 0.625rem;
		}
	}

	&__head {
		display: flex;
		flex-direction: column;
		align-items: center;

		gap: 1.5rem;

		padding: 4.375rem 3.125rem;
		padding-bottom: 3.25rem;

		@media (max-width: 991.98px) {
			padding: 3.125rem 1rem;
		}
	}

	&__logo {
		display: block;
		max-width: 10.5625rem;
		height: auto;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		&Text {
			font-size: 1.875rem;
			font-style: normal;
			font-weight: 800;
			line-height: 106.5%;
			margin-bottom: 0.1875rem;
		}
	}

	&__images {
		display: flex;
		justify-content: space-between;

		gap: 1.875rem;
	}

	&__desktop {
		max-width: 40.625rem;
		width: 100%;
		height: 20.3125rem;

		border-radius: 0.9375rem;
		border: 0.0625rem solid rgba(255, 255, 255, 0.3);
		background: lightgray;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}

		@media (max-width: 991.98px) {
			height: 15rem;
		}

		@media (max-width: 575.98px) {
			height: 9.75rem;
		}
	}
	&__mobile {
		width: 100%;
		max-width: 9.375rem;
		height: 20.3125rem;

		border-radius: 0.9375rem;
		border: 0.0625rem solid rgba(255, 255, 255, 0.3);
		background: lightgray;
		overflow: hidden;

		object-position: top;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media (max-width: 991.98px) {
			display: none;
		}
	}

	&__descr {
		max-width: 40.625rem;

		margin-top: 1.5625rem;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.625rem;

		@media (max-width: 991.98px) {
			margin-top: 1.125rem;
		}

		strong {
			font-size: 1rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}

		span {
			display: block;
			width: 100%;

			text-align: justify;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		p {
			margin: 1rem 0;
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__tasks {
		max-width: 40.625rem;

		margin-top: 1.875rem;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.625rem;

		strong {
			font-size: 1rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}

		span {
			display: block;
			width: 100%;

			text-align: justify;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			gap: 0.5rem;
			li {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.625rem 1.25rem;
				gap: 0.625rem;
				border-radius: 1.25rem;
				background: rgba(255, 255, 255, 0.1);

				text-align: justify;
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
			}
		}
	}

	&__subimage {
		height: auto;
		width: 100%;
		display: flex;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__body {
		padding: 3.75rem 3.125rem;
		padding-top: 0;

		@media (max-width: 991.98px) {
			padding: 1.875rem 1rem;
			padding-top: 0;
		}
	}
}
