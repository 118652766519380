.card {
	padding-top: 3.5625rem;
	border-radius: 1.25rem;
	overflow: hidden;
	transform: translateZ(0);

	@media (max-width: 991.98px) {
		width: 20.1875rem;
		flex-shrink: 0;

		padding-top: 4.125rem;
	}

	@media (min-width: 992px) {
		&:hover {
			.hidden {
				opacity: 1;
				transition: 0.4s ease-in;
			}

			.image {
				transform: rotate(-6.472deg) scale(1.11);
				transition: 0.4s ease-in;
			}

			.element {
				transform: none;
				opacity: 1;
				visibility: visible;
				transition: 0.4s ease-in;
			}

			.wrapper {
				&::before {
					opacity: 1;
					visibility: visible;
					transition: 0.4s ease-in;
				}
			}

			.content {
				top: 0;
				transition: 0.4s ease-in;
			}

			.text {
				opacity: 1;
				transition: 0.4s ease-in;
			}

			.link {
				opacity: 0.8;
				transform: none;
				visibility: visible;
				transition: 0.4s ease-in;
			}
		}
	}
}

.wrapper {
	position: relative;
	border-radius: 1.25rem;

	min-height: 17rem;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	background: #1a2022;

	&::before {
		position: absolute;

		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		content: '';

		border: 0.0625rem solid #9b9b9b;
		border-top: 0;

		pointer-events: none;
		border-radius: 1.25rem;
		z-index: 5;

		transition: 0.4s ease-out;
		opacity: 0;
		visibility: hidden;

		@media (max-width: 991.98px) {
			opacity: 1;
			visibility: var(--color-);
		}
	}

	@media (max-width: 991.98px) {
		min-height: 18.625rem;

		border-radius: 1.25rem;
	}
}

.hidden {
	// opacity: 0;
	position: absolute;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	border-radius: 1.25rem;

	overflow: hidden;

	transition: 0.4s ease-out;

	opacity: 0;

	@media (max-width: 991.98px) {
		opacity: 1;
		border-radius: 1.25rem;
		overflow: hidden;
		transform: translateZ(0);
	}

	&::before {
		position: absolute;

		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		content: '';

		border: 0.0625rem solid #9b9b9b;

		pointer-events: none;
		border-radius: 1.25rem;
		z-index: 3;
	}
}

.element {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	z-index: 3;

	width: 14.625rem;
	height: 12rem;

	transform: translate(-25%, -25%);

	opacity: 0;
	visibility: hidden;

	transition: 0.4s ease-out;

	@media (max-width: 991.98px) {
		transform: none;
		opacity: 1;
		visibility: visible;

		width: 9.0625rem;
		height: 6.4375rem;
		flex-shrink: 0;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: left top;
	}
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	z-index: 2;

	&::before {
		position: absolute;
		content: '';

		z-index: 1;
		pointer-events: none;

		width: 100%;
		height: 100%;

		background: rgba(13, 13, 13, 0.8);
		backdrop-filter: blur(1.5625rem);

		@media (max-width: 991.98px) {
			border-radius: 1.25rem;
			overflow: hidden;
			transform: translateZ(0);
		}
	}
	&::after {
		position: absolute;
		content: '';

		pointer-events: none;

		width: 100%;
		height: 100%;

		background: radial-gradient(4631.99% 85.45% at 100% 100%, #00a2eb 0%, rgba(0, 0, 0, 0) 100%),
			#1a2022;
	}

	img {
		width: 70%;
		height: 100%;
		object-fit: cover;
	}
}

.inner {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;

	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.content {
	display: flex;
	flex-direction: column;

	transition: 0.4s ease-out;

	@media (min-width: 992px) {
		height: 17rem;

		padding-bottom: 2.5rem;

		top: calc(9.125rem - 2.5rem * 2);
		left: 2.875rem;

		align-self: flex-end;

		max-width: 26.5rem;
		width: 100%;

		justify-content: flex-end;

		gap: 0.6875rem;

		z-index: 2;

		position: absolute;
	}

	@media (max-width: 991.98px) {
		width: 100%;

		padding: 1.5625rem;

		position: relative;
		z-index: 4;

		padding-top: 8.4375rem;

		&::before {
			position: absolute;
			content: '';

			left: 0;
			bottom: 0;
			width: 120%;
			height: 105%;
			pointer-events: none;

			background: linear-gradient(0deg, #1a2022 50.52%, rgba(217, 217, 217, 0) 100%);
			filter: blur(1.25rem);
			transform: translate3d(0, 0, 0);
		}

		& > * {
			position: relative;
			z-index: 2;
		}
	}
}

.title {
	// font-size: 2.25rem;
	font-size: 2rem;
	font-style: italic;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;

	max-width: 23.4375rem;

	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;

	@media (max-width: 991.98px) {
		font-size: 1.5rem;
	}
}

.text {
	text-align: justify;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	opacity: 0;

	transition: 0.4s ease-out;

	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;

	@media (max-width: 991.98px) {
		opacity: 1;

		margin-top: 0.6875rem;

		-webkit-line-clamp: 4;
	}
}

.image {
	width: 27.125rem;
	height: 20.5625rem;

	transition: 0.4s ease-out;

	position: absolute;

	@media (min-width: 992px) {
		bottom: 0;
		right: 0;
	}

	@media (max-width: 991.98px) {
		height: 14.5rem;
		width: 100%;

		left: 0;
		top: -4.125rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: bottom;
	}
}

.link {
	position: absolute;
	z-index: 5;

	padding-right: 0.25rem;

	bottom: calc(100% + 0.8125rem);
	right: 0;

	font-size: 1rem;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	letter-spacing: 0.1rem;

	display: flex;
	align-items: flex-end;

	gap: 0.5rem;

	transform: translateX(-30%);
	opacity: 0;
	visibility: hidden;

	transition: 0.3s ease-out;

	color: var(--color-white);

	@media (max-width: 991.98px) {
		transform: none;
		opacity: 1;
		visibility: visible;

		font-size: 0.875rem;
		letter-spacing: 0.0875rem;

		bottom: calc(100% + 0.9375rem);
	}

	&:hover {
		opacity: 1;
		color: var(--color-white);

		svg {
			transform: translateX(0.25rem);
		}
	}

	svg {
		width: 0.9375rem;
		height: 0.9375rem;
		flex-shrink: 0;
		fill: var(--color-white);

		transition: 0.3s ease-in-out;
	}
}
