.section {
	padding: 4.9375rem 0;

	@media (max-width: 991.98px) {
		padding: 2.5rem 0;
	}
}

.wrapper {
	border-radius: 1.25rem;
	border: 0.0625rem inset solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(92deg, #319dce 0%, #982fd3 100%);

	display: flex;
	padding: 2.4375rem 2.0625rem 2.5625rem 4.625rem;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;

	@media (max-width: 991.98px) {
		flex-direction: column;

		padding: 1.5625rem;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 1.25rem;
	}
}

.title {
	@media (max-width: 991.98px) {
		display: flex;
		flex-direction: column;
	}
}

.social {
	@media (max-width: 991.98px) {
		width: 100%;
	}
	ul {
		display: flex;
		align-items: center;

		gap: 1.25rem;

		@media (max-width: 991.98px) {
			justify-content: space-between;
			gap: 0.5rem;
		}
		li {
			a {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 5rem;
				height: 3.75rem;
				padding: 0.875rem 1.5rem;

				border-radius: 1.25rem;
				background: rgba(255, 255, 255, 0.1);

				transition: 0.3s;
				flex-shrink: 0;

				@media (max-width: 991.98px) {
					// width: 3.75rem;
					width: 3rem;
					height: 2.5rem;
					padding: 0.3125rem;
				}

				&:hover {
					background-color: var(--color-white);
					svg {
						fill: var(--color-main);
					}
				}

				svg {
					width: 100%;
					height: 100%;

					@media (max-width: 991.98px) {
						width: 1.375rem;
						height: 1.375rem;
					}

					fill: var(--color-white);

					transition: 0.3s;
				}
			}
		}
	}
}
