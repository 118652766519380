.field {
	width: 100%;

	display: flex;
	height: 3.375rem;
	padding: 0.625rem 2.5rem;
	justify-content: center;
	align-items: center;

	text-align: center;

	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.075rem;
	text-transform: uppercase;

	border-radius: 1.25rem;
	border: 0.0625rem solid #0d0d0d;
	opacity: 0.34;
	box-shadow: 0rem 1.5rem 2.125rem 0rem rgba(150, 49, 212, 0.22);

	transition: 0.3s;

	transition: 0.3s;

	&:focus {
		opacity: 0.8;
	}
}

.wrapper {
	small {
		color: var(--color-major);
		font-size: 0.65rem;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		letter-spacing: 0.075rem;

		text-transform: uppercase;

		margin-top: 0.5rem;
	}
}
