.section {
	overflow: hidden;
	position: relative;
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: radial-gradient(
			55.65% 45.1% at 100% 75.28%,
			rgba(143, 3, 229, 0.31) 0%,
			rgba(143, 3, 229, 0) 100%
		),
		radial-gradient(71.44% 64.48% at 0% 0%, rgba(0, 162, 235, 0.28) 0%, rgba(0, 162, 235, 0) 100%);
}

.wrapper {
	display: flex;
	justify-content: space-between;

	gap: 0.625rem;

	position: relative;

	padding-top: 10.375rem;
	padding-bottom: 5.8125rem;
	min-height: 50.9375rem;

	@media (max-width: 991.98px) {
		padding-top: 10.75rem;
		padding-bottom: 6.375rem;

		flex-direction: column;
		align-items: center;
		text-align: center;

		gap: 0;

		min-height: 31.25rem;
	}
}

.content {
	max-width: 40.75rem;
	width: 100%;

	padding-top: 5.6875rem;

	@media (max-width: 991.98px) {
		position: relative;
		z-index: 2;
	}

	&__wrapper {
		margin-top: 1.875rem;

		display: flex;
		align-items: center;

		gap: 1.25rem;

		@media (max-width: 991.98px) {
			width: 100%;
			margin-top: 1.875rem;
			flex-direction: column;

			gap: 0.625rem;
		}
	}

	@media (max-width: 991.98px) {
		max-width: 100%;
		padding: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.title {
	@media (max-width: 991.98px) {
		line-height: 1.3;

		strong {
			line-height: 1.3;
		}
	}
}

.suptitle {
	font-weight: 300;
	line-height: normal;
	letter-spacing: 0.1rem;

	margin-bottom: 1.1875rem;

	@media (max-width: 991.98px) {
		// max-width: 9.4rem;
		max-width: 11rem;

		letter-spacing: 0.075rem;

		margin-bottom: 1.25rem;

		margin-left: auto;
		margin-right: auto;
	}
}

.text {
	// max-width: 7.5rem;
	max-width: 8rem;
	font-weight: 300;
	line-height: normal;
	letter-spacing: 0.1rem;

	font-size: 0.9375rem;

	@media (max-width: 991.98px) {
		max-width: 100%;

		letter-spacing: 0.075rem;
	}
}

.image {
	max-width: 50.1875rem;
	width: 100%;
	height: 34.75rem;

	position: relative;

	margin-right: calc(-4.6875rem - 3.125rem);

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		pointer-events: none;

		mix-blend-mode: lighten;
	}

	video {
		width: 100%;
		height: 100%;
	}

	.cross {
		position: absolute;
		pointer-events: none;
		top: -1.3125rem;
		left: -1.25rem;

		width: 3.4934rem;
		height: 2.5995rem;
		flex-shrink: 0;
	}

	@media (max-width: 991.98px) {
		width: 29.4375rem;
		height: 20.375rem;

		margin: 0;
		margin-top: -5.8125rem;
	}
}

.cross2 {
	position: absolute;
	pointer-events: none;
	bottom: 7.75rem;
	left: 0;

	width: 3.3946rem;
	height: 2.5441rem;
	flex-shrink: 0;
}

.button {
	@media (max-width: 991.98px) {
		width: 100%;
	}

	&.button--download {
		background: transparent;
		border: 0.0625rem solid rgba(255, 255, 255, 0.1);
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		gap: 0.9375rem;

		&:hover {
			border-color: var(--color-white);
			background-color: var(--color-white);

			color: var(--color-main2);
			svg {
				fill: var(--color-main2) !important;
				opacity: 1;
			}
		}

		div {
			width: 1.125rem;
			height: 1.125rem;

			svg {
				opacity: 0.5;
				fill: var(--color-white) !important;
				transition: 0.3s;
			}
		}
		color: rgba(255, 255, 255, 0.5);

		span {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			strong {
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.075rem;
				text-transform: uppercase;
			}
			small {
				font-size: 0.625rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				letter-spacing: 0.0625rem;
			}
		}
	}
}
