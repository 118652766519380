@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
	--color-white: #ffffff;
	--color-black: #000000;
	--color-main: #0d0d0d;
	--color-main2: #1e1e1e;

	--color-major: #982fd3;
	--color-minor: #319dce;
	--color-light: #d9d9d9;
	--color-light2: #ebebeb;

	// --color-silver: #797a7f;
	// --color-grey: #181a20;
	// --color-dark: #171717;
	// --color-dark21: #212121;
	// --color-dark_grey: #2b271a;

	// --font-main: 'Museo Sans Cyrl', sans-serif;
	--font-main: 'Montserrat', sans-serif;
	// --font-main: 'Raleway', sans-serif;
	--font-second: 'Montserrat', sans-serif;

	--size-container: calc(83.75rem + (1.25rem * 2));

	--size-container-mobile: calc(40rem + (1.25rem * 2));
}

// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
