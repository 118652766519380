.navigation {
	nav {
		ul {
			display: flex;
			align-items: center;
			gap: 3.5625rem;

			@media (max-width: 991.98px) {
				flex-direction: column;
				text-align: center;

				gap: 1rem;
			}

			li {
				a {
					cursor: pointer;

					font-family: var(--font-second);
					font-size: 0.75rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					transition: 0.3s;

					color: var(--color-light);

					&:hover {
						color: var(--color-white);
					}

					@media (max-width: 991.98px) {
						font-size: 1.25rem;
						font-weight: 300;
					}
				}
			}
		}
	}
}
