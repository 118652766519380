.wrapper {
	position: fixed;

	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;

	z-index: 100;

	transition: all 0.3s ease-in-out;

	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	&.active {
		visibility: visible;
		opacity: 1;

		.modal {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;

	width: 100%;
	height: 100%;
}

.body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0.9375rem;
	padding-top: 6rem;
	width: 100%;
	min-height: 100vh;
}

.modal {
	position: relative;
	transform: translate(0px, -100%);

	opacity: 0;
	z-index: 10;
	transition: all 0.3s ease-in-out;

	border-radius: 1.25rem;
	background: linear-gradient(0deg, rgba(143, 3, 229, 0.2) 0%, rgba(143, 3, 229, 0) 51.61%), #1a2022;
	color: var(--color-white);
	// padding: 20px 24px;
	padding: 0;
	width: 100%;
	max-width: 58.125rem;
	pointer-events: auto;
}

.close {
	position: absolute;

	bottom: calc(100% + 1.25rem);
	left: 50%;
	transform: translateX(-50%);

	width: 2.25rem;
	height: 2.25rem;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5rem;

	border: 0.0625rem solid rgba(255, 255, 255, 0.1);
	box-shadow: 0rem 1.5rem 2.125rem rgba(150, 49, 212, 0.22);

	z-index: 10;

	transition: 0.3s;

	cursor: pointer;
	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	&::before {
		position: absolute;
		content: '';

		width: 0.875rem;
		height: 0.0625rem;

		border-radius: 0.125rem;

		background-color: var(--color-white);

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&::after {
		position: absolute;
		content: '';

		height: 0.875rem;
		width: 0.0625rem;

		border-radius: 0.125rem;

		background-color: var(--color-white);

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	svg {
		fill: var(--color-white);
		width: 0.75rem;
		height: 0.75rem;
		transition: 0.3s;
	}
}

// modal-project
