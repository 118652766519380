.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	appearance: none;
	cursor: pointer;

	text-transform: uppercase;

	border: 0.0625rem inset solid rgba(255, 255, 255, 0.1);
	background: var(--color-major);

	border-radius: 1.25rem;

	height: 3.375rem;
	min-width: 8.75rem;
	padding: 0.625rem 2.4375rem;

	gap: 0.625rem;

	color: var(--color-white);
	font-size: 0.75rem;
	font-weight: bold;
	line-height: 0.875rem;
	letter-spacing: 0.075rem;

	position: relative;

	transition: 0.3s;

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 1.25rem;

		background: linear-gradient(92deg, #319dce 0%, #982fd3 100%);

		transition: 0.3s;
	}
	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 1.25rem;
		border: 0.0625rem solid rgba(255, 255, 255, 0.1);

		transition: 0.3s;
	}

	&:hover {
		color: var(--color-white);
		&::after {
			border-color: var(--color-white);
		}
	}

	&:active {
		transform: translateY(0.125rem);
	}

	.icon {
		flex-shrink: 0;
		width: 1.3125rem;
		height: 1.3125rem;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--color-white);

			transition: 0.3s;
		}
	}

	&.white {
		background-color: var(--color-white);
		color: var(--color-main);

		padding: 0.625rem;

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}

		.icon {
			svg {
				fill: var(--color-main);
			}
		}

		&:hover {
			background: rgba(255, 255, 255, 0.1);
			color: var(--color-white);

			.icon {
				svg {
					fill: var(--color-white);
				}
			}
		}
	}
	&.transparent {
		background: rgba(255, 255, 255, 0.1);
		color: var(--color-white);

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}

		.icon {
			svg {
				fill: var(--color-white);
			}
		}

		&:hover {
			background: rgba(255, 255, 255, 0.3);
			color: var(--color-white);

			.icon {
				svg {
					fill: var(--color-white);
				}
			}
		}
	}
}
