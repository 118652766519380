.language {
	position: relative;

	.trigger {
		border-radius: 1.25rem;
		border: 0.0625rem solid rgba(255, 255, 255, 0.4);

		cursor: pointer;
		user-select: none;
		transition: 0.3s;

		display: flex;
		width: 3.125rem;
		height: 2.75rem;
		padding: 0.625rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		text-transform: uppercase;

		flex-shrink: 0;

		color: var(--color-light);

		font-family: var(--font-second);
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&:hover {
			border-color: var(--color-white);
		}
	}

	.dropdown {
		position: absolute;
		top: calc(100% + 0.5rem);
		left: 50%;
		transform: translateX(-50%);
		width: 100%;

		border: 0.0625rem solid rgba(255, 255, 255, 0.4);
		background-color: var(--color-dark);
		border-radius: 0.625rem;

		z-index: 5;

		overflow: hidden;

		ul {
			li {
				cursor: pointer;
				padding: 0.625rem;

				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;

				gap: 0.625rem;

				font-size: 0.875rem;
				line-height: 165.8%;

				text-transform: uppercase;

				color: var(--color-light);
				transition: 0.3s;

				&:hover {
					background: rgba(255, 255, 255, 0.3);
					color: var(--color-white);
				}
			}
		}
	}
}
