.section {
	padding-top: 2.75rem;
	padding-bottom: 4.125rem;

	@media (max-width: 991.98px) {
		padding-top: 1.875rem;
		padding-bottom: 2.5rem;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;

	gap: 1.9375rem;

	@media (max-width: 991.98px) {
		gap: 1.25rem;
	}
}

.tabs {
	display: flex;
	flex-direction: column;

	gap: 2.75rem;

	@media (max-width: 991.98px) {
		gap: 1.25rem;
	}
}

.tablist {
	display: flex;
	gap: 1.25rem;

	@media (max-width: 991.98px) {
		flex-wrap: wrap;

		gap: 0.3125rem;
	}
}

.tab {
	user-select: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	appearance: none;
	cursor: pointer;

	text-transform: uppercase;

	border: 0.0625rem inset solid rgba(255, 255, 255, 0.1);
	background: transparent;

	border-radius: 1.25rem;

	min-height: 3.375rem;
	min-width: 8.75rem;
	padding: 0.625rem 2.4375rem;

	gap: 0.625rem;

	color: var(--color-white);
	font-size: 0.75rem;
	font-weight: bold;
	line-height: 0.875rem;
	letter-spacing: 0.075rem;

	outline: none;

	position: relative;

	transition: 0.3s;

	@media (max-width: 991.98px) {
		min-height: 2.625rem;
		min-width: 6.25rem;

		padding: 0.9375rem;

		font-size: 0.625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.0625rem;
	}

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 1.25rem;

		background: linear-gradient(92deg, #319dce 0%, #982fd3 100%);
		opacity: 0;
		visibility: hidden;

		transition: 0.3s;
	}
	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 1.25rem;
		border: 0.0625rem solid rgba(255, 255, 255, 0.1);

		transition: 0.3s;
	}

	&:hover {
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}

	&:global(.react-tabs__tab--selected) {
		pointer-events: none;
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}
}

.service {
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	gap: 2rem;

	@media (max-width: 991.98px) {
		flex-direction: column;
		width: 100%;

		gap: 1.25rem;
	}
}

.content {
	max-width: 27.25rem;
	width: 100%;

	padding-top: 3.5625rem;

	@media (max-width: 991.98px) {
		max-width: 100%;
		padding: 0;
	}
}

.label {
	max-width: 18.125rem;

	display: flex;
	flex-direction: column;

	& + .text {
		margin-top: 2.5625rem;
	}
}

.technologies {
	margin-top: 3.375rem;

	display: flex;
	flex-direction: column;

	gap: 1.1875rem;

	@media (max-width: 991.98px) {
		margin-top: 1.25rem;

		gap: 0.625rem;
	}

	&__label {
		font-size: 1.5rem;
		font-style: italic;
		font-weight: 250;
		line-height: normal;

		@media (max-width: 991.98px) {
			font-size: 1rem;
		}
	}

	&__list {
		ul {
			gap: 0.625rem 1.25rem;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 991.98px) {
				gap: 0.3125rem 0.625rem;
			}
			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				position: relative;

				flex-shrink: 0;
				width: 5rem;
				height: 3.75rem;

				border-radius: 1.25rem;
				border: 0.0625rem solid rgba(255, 255, 255, 0.1);

				overflow: hidden;

				@media (max-width: 991.98px) {
					width: 3.75rem;
					height: 2.5rem;
				}

				&:hover {
					&::before {
						opacity: 1;
						visibility: visible;
					}
				}

				&::before {
					position: absolute;
					pointer-events: none;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					content: '';

					transition: 0.3s;

					opacity: 0;
					visibility: hidden;

					background: radial-gradient(
							7666.23% 141.42% at 100% 0%,
							rgba(0, 162, 235, 0.4) 0%,
							rgba(0, 0, 0, 0) 100%
						),
						#1a2022;
				}

				img {
					position: relative;
					z-index: 2;

					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}
}

.text {
	margin-top: 5.5rem;

	font-style: normal;
	font-weight: 300;
	line-height: normal;

	text-align: justify;

	@media (max-width: 991.98px) {
		margin-top: 1.25rem;
	}
}

.button {
	margin-top: 1.6875rem;

	@media (max-width: 991.98px) {
		margin-top: 1.875rem;
		width: 100%;
	}
}

.cards {
	display: flex;
	flex-direction: column;

	gap: 1.5625rem;

	@media (min-width: 992px) {
		max-width: 48.4375rem;
		width: 100%;
	}

	@media (max-width: 991.98px) {
		margin-top: 1.25rem;
		flex-direction: row;

		gap: 1.25rem;
		overflow-x: auto;

		width: calc(100% + 0.875rem * 2);
		margin-left: -0.875rem;

		padding: 0 0.875rem;

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

.tabpanel {
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	&:global(.react-tabs__tab-panel--selected) {
		animation: fadeIn 2s;
	}
}
