.form {
}

.content {
	display: flex;
	flex-direction: column;

	gap: 1.25rem;

	@media (max-width: 991.98px) {
		gap: 0.5rem;
	}
}

.fields {
	display: flex;
	flex-wrap: wrap;

	gap: 1.25rem;

	@media (max-width: 991.98px) {
		gap: 0.5rem;
	}

	.field {
		width: calc(100% / 2 - 0.625rem);

		@media (max-width: 991.98px) {
			width: 100%;
		}
	}
}

.controls {
	display: flex;
	flex-wrap: wrap;

	gap: 1.25rem;

	@media (max-width: 991.98px) {
		flex-direction: column;

		gap: 0.5rem;
	}

	> * {
		width: calc(100% / 2 - 0.625rem);

		@media (max-width: 991.98px) {
			width: 100%;
		}
	}
}
