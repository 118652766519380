.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	z-index: 10;

	border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		backdrop-filter: blur(1.5625rem);
		transform: translate3d(0, 0, 0);
	}

	height: 5rem;
	padding: 0.625rem 0;

	display: flex;
	flex-direction: column;
	justify-content: center;

	transition: 0.3s linear;

	@media (max-width: 991.98px) {
		// height: 6rem;
	}

	&.dark {
		background: rgba(13, 13, 13, 0.8);
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		gap: 1.25rem;
	}

	.logo {
		display: block;
		width: 4.0894rem;
		height: 1.3122rem;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		@media (max-width: 991.98px) {
			width: 4.0894rem;
			height: 1.3122rem;
		}
	}

	.button {
		margin-left: auto;

		margin-right: 5.6875rem;

		a,
		button {
			min-height: 2.75rem;

			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.burger,
.close {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	height: 2.75rem;
	width: 4.9375rem;
	gap: 0.625rem;

	border-radius: 1.25rem;
	background: var(--color-white);

	svg {
		width: 1.1875rem;
		height: 0.9375rem;

		fill: var(--color-main);
	}
}

.close {
	width: 3.125rem;
}

.inner {
	display: flex;
	align-items: center;
	gap: 3.5625rem;
}

.menu {
	@media (max-width: 991.98px) {
		visibility: hidden;
		opacity: 0;

		display: flex;
		flex-direction: column;

		z-index: 20;
		position: fixed;

		border-radius: 2.5rem 0rem 0rem 2.5rem;
		background: linear-gradient(92deg, #319dce 0%, #982fd3 100%);

		overflow-y: auto;
		top: 0;
		height: 100%;

		max-width: 16rem;
		width: 100%;
		right: -100%;
		transition: 0.3s;

		color: var(--color-white);

		padding-left: 0.3125rem;
		padding-bottom: 2.5rem;

		&.active {
			right: 0;
			visibility: visible;
			opacity: 1;
		}

		&__head {
			display: flex;
			align-items: center;

			padding: 1.5625rem 1.25rem;
			justify-content: space-between;
			width: 100%;
		}

		.navigation {
			@media (max-width: 991.98px) {
				padding: 1.25rem 0;
				margin: auto;
			}
		}
		.button {
			margin: auto;
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	background-color: var(--color-main);
	z-index: 8;

	transition: 0.4s;

	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 0.4;
		visibility: visible;
		left: 0;
	}
}
