.logo {
	width: 5.125rem;
	height: 5.125rem;
	display: block;
	flex-shrink: 0;

	margin: auto;

	&__el2 {
		animation: fillAnimation 3s linear infinite;
	}
	&__el {
		animation: fillAnimation2 3s linear infinite;
	}
	svg {
		width: 100%;
		height: 100%;
	}

	@keyframes fillAnimation {
		0% {
			stroke-dasharray: 94;
			stroke: transparent;
		}
		33% {
			stroke: white;
		}

		66% {
			stroke: white;
		}
		100% {
			stroke-dasharray: 240;
			stroke: transparent;
		}
	}
	@keyframes fillAnimation2 {
		0% {
			stroke-dasharray: 41;
			stroke: transparent;
		}
		33% {
			stroke: white;
		}

		66% {
			stroke: white;
		}
		100% {
			stroke-dasharray: 110;
			stroke: transparent;
		}
	}
}

.page {
	position: fixed;
	z-index: 1000;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: #0d0d0d;

	padding-top: 4.125rem;
	padding-bottom: 4.125rem;

	// &:not(.loading) {
	// 	animation: 3s linear 2s running load;
	// }

	// @keyframes load {
	// 	0% {
	// 		opacity: 0;
	// 	}

	// 	20% {
	// 		opacity: 1;
	// 	}

	// 	80% {
	// 		opacity: 1;
	// 	}

	// 	100% {
	// 		opacity: 0;
	// 	}
	// }
}
.countdown {
	display: flex;
	align-items: center;

	gap: 0.25rem;

	text-align: center;

	font-size: 1.5rem;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
	text-transform: uppercase;
}
