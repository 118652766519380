.section {
	margin-top: -5.6875rem;

	padding-bottom: 2.75rem;

	@media (max-width: 991.98px) {
		padding: 0;
		margin-top: -4.5625rem;
	}
}

.wrapper {
	border-radius: 1.25rem;
	position: relative;

	overflow: hidden;

	padding: 1.375rem 1.1875rem 1.3125rem 8.25rem;

	background: radial-gradient(
			471.67% 122.15% at 100% 0%,
			rgba(143, 3, 229, 0.2) 0%,
			rgba(143, 3, 229, 0) 100%
		),
		#1a2022;

	@media (max-width: 991.98px) {
		min-height: 26.75rem;
		padding: 2.1875rem 1.5625rem;

		background: radial-gradient(
				94.5% 74.31% at 100% 100%,
				rgba(143, 3, 229, 0.2) 0%,
				rgba(143, 3, 229, 0) 100%
			),
			radial-gradient(154.75% 141.42% at 0% 0%, rgba(0, 162, 235, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
			#1a2022;
	}
}

.image {
	position: absolute;
	pointer-events: none;

	width: 20.3125rem;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: left bottom;
	}

	@media (min-width: 992px) {
		top: 0;
		left: 0;
		bottom: 0;
	}

	@media (max-width: 991.98px) {
		width: 20.3125rem;
		height: 13.6875rem;

		bottom: 0;
		right: 0;
	}
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;

	// gap: 2.1875rem;
	gap: 2rem;

	position: relative;
	z-index: 2;

	@media (max-width: 991.98px) {
		flex-direction: column;
		align-items: stretch;

		gap: 1.25rem;
	}
}

.text {
	// max-width: 25.8125rem;
	max-width: 27rem;
	width: 100%;

	font-style: italic;
	font-weight: 250;
	line-height: normal;

	strong {
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: uppercase;
	}

	@media (max-width: 991.98px) {
		font-size: 1rem;
	}
}

.list {
	max-width: 46.3125rem;
	width: 100%;

	border-radius: 1rem;
	background: rgba(13, 13, 13, 0.3);

	@media (max-width: 991.98px) {
		max-width: 100%;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 2.125rem;

		gap: 3.375rem;

		@media (max-width: 991.98px) {
			flex-wrap: wrap;
			align-items: stretch;
			justify-content: stretch;

			padding: 1.5625rem;

			gap: 1.875rem 0;
		}

		li {
			display: flex;
			flex-direction: column;

			position: relative;

			@media (max-width: 991.98px) {
				width: calc(100% / 2);
			}

			&:last-child {
				&::after {
					display: none;
				}
			}

			@media (max-width: 991.98px) {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(5),
				&:nth-child(6) {
					&::after {
						display: block;
					}
				}

				&:nth-child(even) {
					padding-left: 0.5rem;
				}
			}

			&::after {
				position: absolute;

				top: 0;
				right: -1.6875rem;

				content: '';

				height: 100%;

				width: 0.0625rem;
				flex-shrink: 0;

				background: var(--color-white);
				opacity: 0.1;

				@media (max-width: 991.98px) {
					right: 0;
					top: calc(100% + 0.9375rem);

					width: 100%;
					height: 0.0625rem;

					display: none;
				}
			}

			span {
				display: inline-block;
				opacity: 0.5;

				font-size: 1rem;

				font-style: italic;
				font-weight: 250;
				line-height: normal;

				margin-top: -0.4375rem;

				@media (max-width: 991.98px) {
					font-size: 0.875rem;
				}
			}
			strong {
				font-size: 3rem;
				font-style: italic;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;

				@media (max-width: 991.98px) {
					font-size: 2.25rem;
				}

				small {
					font-size: 1.5rem;

					@media (max-width: 991.98px) {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}
