.checkbox-label {
	display: flex;
	align-items: center;

	font-size: 0.75rem;
	font-style: normal;
	font-weight: 250;
	line-height: normal;
	letter-spacing: 0.075rem;

	user-select: none;

	cursor: pointer;

	position: relative;

	input {
		outline: none;
		appearance: none;
		-webkit-appearance: none;
		border: 0;
		background-image: none;
		background-color: transparent;
		box-shadow: none;
		position: absolute;
		z-index: -1;
		opacity: 0;
		width: 0.001rem;
		height: 0.001rem;
	}
}

.checkbox-input:checked + .checkbox-checkmark {
	background-color: var(--color-main);
}

.checkbox-checkmark {
	position: relative;
	margin-right: 0.625rem;

	width: 1rem;
	height: 1rem;
	flex-shrink: 0;

	border: 0.0625rem solid var(--color-main);
	border-radius: 0.1875rem;
}

.checkbox-checkmark:after {
	content: '';
	position: absolute;
	opacity: 0;
	visibility: hidden;

	transition: 0.3s;

	left: 0.325rem;
	top: 0.125rem;
	width: 0.25rem;
	height: 0.5rem;
	border: solid var(--color-white);
	border-width: 0 0.125rem 0.125rem 0;
	transform: rotate(45deg);
}

.checkbox-input:checked + .checkbox-checkmark:after {
	opacity: 1;
	visibility: visible;
}
